import React, { useEffect, useState, useContext } from "react";
import AddUser from "./Users/Add";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import UserList from "./Users/UserList";
import Search from "./Users/Search";
import Hierarchy from "./Users/Hierarchy";
import helpers from "../utils/helpers";
import constants from "../utils/constants";
import { compact, isEmpty, startCase } from "lodash";

const Home = () => {
  const location = useLocation();
  const user_params = compact(location.pathname.split("/"));
  let { user } = useContext(AuthContext);
  let user_by_created = user.id;
  let userType = user.userType;
  if (!isEmpty(user_params[0])) {
    user_by_created = user_params[0];
  }
  if (!isEmpty(user_params[1])) {
    userType = user_params[1];
  }
  const [show_model, setShowModel] = useState(false);
  const [reset, setRest] = useState(false);
  const [results, setResults] = useState([]);
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
    keyword: "",
    status: "active",
    created_by: user_by_created,
    userType: userType,
  });
  const [balance, setBalance] = useState({
    totalAvailableLimit: 0,
    totalAmount: 0,
    totalCoins: 0,
    playerBalance: 0,
    availableLimit: 0,
    exposure: 0,
  });

  const onSubmit = (params) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        keyword: params.keyword,
        status: params.status,
      };
    });
  };

  const handlePageClick = (event) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  const getUsers = async (obj) => {
    const { status, data: response_users } = await apiGet(
      apiPath.downLineList,
      !isEmpty(obj) ? obj : search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setResults(response_users?.results || []);
        if ("balance" in response_users?.results) {
          setBalance((prevState) => {
            const balance_info = response_users?.results?.balance;
            return {
              ...prevState,
              totalAvailableLimit: balance_info.totalAvailableLimit,
              totalAmount: balance_info.totalAmount,
              totalCoins: balance_info.totalCoins,
              playerBalance: balance_info.playerBalance,
              availableLimit: balance_info.availableLimit,
              exposure: balance_info.exposure,
              playerExposure: balance_info.playerExposure,
            };
          });
        }
      }
    }
    setRest(false);
  };

  const resetList = () => {
    setRest(true);
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: 1,
        page_size: 10,
        keyword: "",
        status: "active",
      };
    });
  };

  const refreshUsers = () => {
    getUsers();
  };

  useEffect(() => {
    const user_params = compact(location.pathname.split("/"));
    let user_by_created = user.id;
    if (!isEmpty(user_params[0])) {
      user_by_created = user_params[0];
      setSearchParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          page_size: 10,
          keyword: "",
          status: "active",
          created_by: user_by_created,
          userType: userType,
        };
      });
    } else {
      setSearchParams((prevState) => {
        return {
          ...prevState,
          page: 1,
          page_size: 10,
          keyword: "",
          status: "active",
          created_by: user.id,
          userType: user?.userType,
        };
      });
    }
  }, [location]);

  useEffect(() => {
    getUsers();
  }, [
    search_params?.page,
    search_params?.keyword,
    search_params?.status,
    search_params?.created_by,
    search_params?.userType,
  ]);

  const [message, setMessage] = useState([]);
  const messageList = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    hostname = hostname || "sabaexch.com";
    const { status, data: response_users } = await apiGet(
      apiPath.messageList + "&domain=" + hostname
    );
    if (status === 200) {
      if (response_users.success) {
        setMessage(response_users.results);
      }
    }
  };
  useEffect(() => {
    messageList();
  }, []);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getUsers(search_params);
    }, 10 * 1000);

    return () => clearInterval(intervalID);
  }, [search_params]);

  const availableBalance =
    (balance?.totalAmount &&
      Math.abs(balance?.totalAmount) - Math.abs(balance?.playerExposure)) ||
    0;

  return (
    <div>
      <section className="breadcum-sec">
        <Container fluid>
          <div className="bredcum-sec-main">
            <h5 className="mb-0">
              <i className="fa-solid fa-microphone"></i> News
            </h5>

            <marquee width="50%">
              {message.map((res) => {
                return (
                  <a href="#" style={{ marginRight: "10px" }}>
                    <span>
                      {helpers.msgDateFormat(res.msgDate, user.timeZone)}
                    </span>
                    {res.title} - {res.message}
                  </a>
                );
              })}
            </marquee>
          </div>
        </Container>
      </section>
      <div className="user_data_div">
        <section className="find-member-sec pb-3">
          <Container fluid className="px-0">
            <Row>
              <Col xl={12} className="mb-md-0 mb-3">
                <Row>
                  <Col xl={4} lg={6} md={12} className="">
                    <Search
                      onSubmit={onSubmit}
                      search_params={search_params}
                      reset={reset}
                    />
                  </Col>
                  <Col xl={8} lg={6} md={12}>
                    <div className="d-flex flex-wrap justify-content-between">
                      <Hierarchy results={results} />
                      <div className="find-member-director text-xl-end ">
                        {["sub_owner", "super_admin"]?.includes(
                          user?.userType
                        ) && (
                          <a
                            href="#"
                            className="btn"
                            onClick={() => {
                              setShowModel(!show_model);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              height="1.25rem"
                              width="1.25rem"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>{" "}
                            Add{" "}
                            {startCase(
                              user_params[1] !== "agent" &&
                                user?.userType !== "agent"
                                ? constants.layerPanel1[
                                    user_params[1] !== undefined &&
                                    user_params[0] !== ""
                                      ? user_params[1]
                                      : user?.userType
                                  ]
                                : "Player"
                            )}
                          </a>
                        )}

                        {!["sub_owner", "super_admin"]?.includes(
                          user?.userType
                        ) &&
                          isEmpty(user_params[1]) && (
                            <a
                              href="#"
                              className="btn"
                              onClick={() => {
                                setShowModel(!show_model);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                height="1.25rem"
                                width="1.25rem"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>{" "}
                              Add{" "}
                              {startCase(
                                user_params[1] !== "agent" &&
                                  user?.userType !== "agent"
                                  ? constants.layerPanel1[
                                      user_params[1] !== undefined &&
                                      user_params[0] !== ""
                                        ? user_params[1]
                                        : user?.userType
                                    ]
                                  : "Player"
                              )}
                            </a>
                          )}
                        <a
                          href="#"
                          className="btn"
                          onClick={() => getUsers(search_params)}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            height="1.25rem"
                            width="1.25rem"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="total-balance-sec">
          <Container fluid className="px-0">
            <ul className="list-unstyled">
              <li>
                <dt>Total Balance</dt>
                <strong>
                  PBU {`${helpers.currencyFormat(balance?.totalAmount)}`}
                </strong>
              </li>
              <li>
                <dt>Total Exposure</dt>
                <strong>
                  PBU{" "}
                  <span className="text-danger">
                    ({`${helpers.currencyFormat(balance?.playerExposure)}`})
                  </span>
                </strong>
              </li>

              <li>
                <dt>Total Avail. bal.</dt>
                <strong>
                  PBU {`${helpers.currencyFormat(availableBalance)}`}
                </strong>
              </li>

              <li>
                <dt>Balance</dt>
                <strong>
                  PBU {`${helpers.currencyFormat(balance?.totalCoins)}`}
                </strong>
              </li>

              <li>
                <dt>Available Balance</dt>
                <strong>
                  PBU{" "}
                  {`${helpers.currencyFormat(
                    Math.abs(availableBalance) + balance?.totalCoins
                  )}`}
                </strong>
              </li>

              <li>
                <dt>Total Player Balance</dt>
                <strong>
                  PBU {`${helpers.currencyFormat(balance?.playerBalance)}`}
                </strong>
              </li>
            </ul>
          </Container>
        </section>
        <UserList
          results={results}
          handlePageClick={handlePageClick}
          getUsers={getUsers}
          data={user.userType}
        />
        <div id="descFooter" class="footer-sticky justify-end space-x-1">
          {(user.userType == "super_admin" ||
            user.userType == "sub_owner" ||
            user.userType == "owner") && (
            <>
              <div class=" btn btn-light hover:bg-blue-50 ">
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  height="1.25rem"
                  width="1.25rem"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.935 13.581a12.24 12.24 0 0 1-.653 7.463l.004.004-1.908-1.1.251 7.209L32 23.769l-2.054-1.183c3.201-7.08.57-15.57-6.3-19.535A15.29 15.29 0 0 0 8.41 3.015l1.517 2.657a12.24 12.24 0 0 1 18.008 7.909ZM4.717 11.553a12.239 12.239 0 0 0 17.356 15.37l1.517 2.657a15.29 15.29 0 0 1-15.237-.036C1.484 25.58-1.147 17.09 2.054 10.01L0 8.83l6.37-3.388.252 7.21-1.905-1.1Zm12.613-3.12a1.62 1.62 0 0 0-1.109-.432L16.216 8c-.415 0-.814.155-1.108.432-.294.277-.46.653-.46 1.044v.223c-.81.18-1.56.497-2.19.934-1.02.707-1.808 1.805-1.808 3.151 0 1.146.44 2.254 1.601 2.99 1.029.652 2.412.867 3.967.867 1.367 0 2.08.2 2.416.406.197.121.38.298.38.897 0 .437-.165.669-.517.868-.449.253-1.216.434-2.279.434-1.115 0-1.98-.345-2.437-.729a1.63 1.63 0 0 0-1.132-.374 1.608 1.608 0 0 0-1.082.49 1.408 1.408 0 0 0-.245 1.624c.09.173.214.33.368.46.785.662 1.823 1.117 2.96 1.334v.2c0 .392.165.767.46 1.044a1.62 1.62 0 0 0 1.108.432 1.62 1.62 0 0 0 1.109-.432c.294-.277.46-.652.46-1.044v-.162c.827-.121 1.63-.354 2.325-.747 1.226-.694 2.038-1.85 2.038-3.398 0-1.384-.544-2.597-1.802-3.37-1.116-.684-2.585-.883-4.128-.883-1.353 0-1.97-.209-2.214-.364-.112-.07-.217-.152-.217-.541 0-.188.107-.48.535-.777.428-.296 1.093-.525 1.897-.525 1.118 0 1.915.432 2.242.838.123.159.279.293.457.394a1.664 1.664 0 0 0 1.201.165 1.61 1.61 0 0 0 .558-.254c.165-.119.305-.268.409-.438a1.392 1.392 0 0 0 .153-1.134 1.448 1.448 0 0 0-.281-.519c-.745-.923-1.89-1.552-3.17-1.834v-.225c0-.391-.166-.766-.46-1.043Z"
                  ></path>
                </svg>
              </div>
              <p>Payment Check List</p>

              <div class=" btn btn-light hover:bg-blue-50 ">
                <svg
                  viewBox="0 0 19 12"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  height="1.25rem"
                  width="1.25rem"
                >
                  <path
                    fill-rule="evenodd"
                    d="m11.372 11.364.257-2.289H8.432c1.1-1.794 1.816-3.077 2.148-3.85.332-.775.498-1.517.498-2.23 0-.856-.236-1.57-.706-2.14C9.902.285 9.286 0 8.525 0c-.766 0-1.45.284-2.052.851l-.257 2.257c.87-.68 1.524-1.02 1.96-1.02.28 0 .516.107.705.321.19.214.284.48.284.795 0 .637-.345 1.584-1.033 2.84-.688 1.255-1.674 2.908-2.959 4.958v.362h6.199Z"
                  ></path>
                  <path
                    d="m1.96 11.364.488-4.225h1.55c1.016 0 1.826-.313 2.43-.94.606-.626.908-1.53.908-2.714 0-1.033-.282-1.848-.845-2.445C5.93.443 5.196.145 4.295.145H1.286L0 11.364h1.96ZM3.476 5.38h-.825l.39-3.462h.474c.576 0 1.02.149 1.33.446.31.297.465.703.465 1.217 0 .557-.159.996-.475 1.317-.317.321-.77.482-1.36.482ZM12.768 11.364l.488-4.225h1.55c1.016 0 1.827-.313 2.431-.94.605-.626.908-1.53.908-2.714 0-1.033-.282-1.848-.845-2.445-.563-.597-1.295-.895-2.197-.895h-3.008l-1.286 11.219h1.959Zm1.517-5.983h-.824l.389-3.462h.475c.576 0 1.02.149 1.33.446.31.297.464.703.464 1.217 0 .557-.158.996-.475 1.317-.316.321-.77.482-1.359.482Z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <p>P2P Transfer Log</p>
            </>
          )}

          <div class=" btn btn-light hover:bg-blue-50 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              height="1.25rem"
              width="1.25rem"
            >
              <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"></path>
            </svg>
          </div>
          <p>Betting Profit &amp; Loss</p>
          <div class=" btn btn-light hover:bg-blue-50 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              height="1.25rem"
              width="1.25rem"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <p>Betting History</p>
          <div class=" btn btn-light hover:bg-blue-50 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              height="1.25rem"
              width="1.25rem"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <p>Profile</p>
          {(user.userType == "super_admin" ||
            user.userType == "sub_owner" ||
            user.userType == "owner") && (
            <>
              <div class=" btn btn-light hover:bg-blue-50 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  height="1.25rem"
                  width="1.25rem"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <p>Change Status</p>
            </>
          )}
        </div>
      </div>
      {show_model ? (
        <AddUser
          refreshUsers={refreshUsers}
          id={user_params[0] ? user_params[0] : user._id}
          slug={user_params[1] ? user_params[1] : user.userType}
          setShowModel={() => {
            setShowModel(!show_model);
          }}
        />
      ) : null}
    </div>
  );
};

export default Home;
