import ActiveMatch from "./pages/ActiveMatch";
import AddMatch from "./pages/AddMatch";
import AprofitCasino from "./pages/AprofitCasino";
import AprofitDownline from "./pages/AprofitDownline";
import AprofitByDownline from "./pages/AprofitByDownline";
import AprofitMarket from "./pages/AprofitMarket";
import AprofitPlayer from "./pages/AprofitPlayer";
import Adownlinesportspl from "./pages/Adownlinesportspl";
import ACdownlinesportspl from "./pages/ACdownlinesportspl";
import ACasinoprofitAndLossDownlineNew from "./pages/ACasinoprofitAndLossDownlineNew";
import ICasinoprofitAndLossDownlineNew from "./pages/ICasinoprofitAndLossDownlineNew";
import Banking from "./pages/Banking/Banking";
import BetCount from "./pages/BetCount";
import BetList from "./pages/BetList";
import BetListLive from "./pages/BetListLive";
import UserBetListLive from "./pages/UserBetListLive/UserBetListLive";
import BettingHistory from "./pages/BettingHistory/BettingHistory";
import BettingProfitLoss from "./pages/BettingProfitLoss/BettingProfitLoss";
import BlockMarket from "./pages/BlockMarket";
import DefaultSetting from "./pages/DefaultSetting";
import FancyResult from "./pages/FancyResult";
import GeneralSetting from "./pages/Generalsetting";
import Other from "./pages/Other";
import Home from "./pages/Home";
import HyperMessage from "./pages/HyperMessage";
import ImportantMessage from "./pages/ImportantMessage";
import InActiveMatch from "./pages/InActiveMatch";
import InactiveUsers from "./pages/InactiveUsers";
import LiveMatchBet from "./pages/LiveMatchBet";
import ManageLinks from "./pages/ManageLinks";
import Manupulation from "./pages/Manupulation";
import MarketResult from "./pages/MarketResult";
import TossResult from "./pages/TossResult";
import MyAccountStatement from "./pages/MyAccount/MyAccountStatement";
import MyAccountSummary from "./pages/MyAccount/MyAccountSummary";
import MyActivityLog from "./pages/MyAccount/MyActivityLog";
import MyProfile from "./pages/MyAccount/MyProfile";
import TransactionLogs from "./pages/MyAccount/TransactionLogs";
import PlayerBalance from "./pages/PlayerBalance";
import PreMatch from "./pages/PreMatch";
import RejectedBets from "./pages/RejectedBets/RejectedBets";
import ViewFancyDetails from "./pages/RejectedBets/ViewFancyDetails";
import Result from "./pages/Result";
import RiskManagement from "./pages/RiskManagement/RiskManagement";
import SearchMatch from "./pages/SearchMatch";
import SearchUser from "./pages/SearchUser";
import SetBookMarker from "./pages/SetBookMarker";
import SetLimit from "./pages/SetLimit";
import SportSetting from "./pages/SportSetting";
import StatementByUser from "./pages/StatementByUser";
import SurveillanceSetting from "./pages/SurveillanceSetting";
import SuspendedResult from "./pages/SuspendedResult";
import UserMessage from "./pages/UserMessage";
import AccountSummary from "./pages/Users/AccountSummary";
import ActivityLog from "./pages/Users/ActivityLog";
import TransactionHistory from "./pages/Users/TransactionHistory";
import TransactionHistory2 from "./pages/Users/TransactionHistory2";
import ViewCasinoBetDialog from "./pages/InternationalCasionBets";
import ViewAECasionBets from "./pages/AECasionBets";
import WebsiteSetting from "./pages/WebsiteSetting";
import BetLockUser from "./pages/BetLockUser";
import Statements from "./pages/Statements";
import SuspendedMarketResult from "./pages/SuspendedMarketResult";
import SuspendedFancyResult from "./pages/SuspendedFancyResult";
import viewBets from "./pages/viewBets";
import updateFancyStatus from "./pages/updateFancyStatus";
import UserProfitLoss from "./pages/UserProfitLoss";
import MatchProfitLoss from "./pages/MatchProfitLoss";
import ShowBetsCR from "./pages/ShowBetsCR";
import DisplayMatchBet from "./pages/DisplayMatchBet";
import DisplaySessionBet from "./pages/DisplaySessionBet";
import AddWebsite from "./pages/AddWebsite";
import CurrentBets from "./pages/CurrentBets";
import MatchedAll from "./pages/RiskManagement/MatchedAll";
import BannerList from "./pages/Banner/List";
import Setting from "./pages/Setting";
import Events from "./pages/Events";
import CasinoListing from "./pages/CasinoListing";
import ReportType from "./pages/Report/ReportType";
import RecentCreatedAccount from "./pages/Report/RecentCreatedAccount";
import SportAnalytics from "./pages/SportAnalytics/SportAnalytics";

import P2pTransfer from "./pages/P2pTransfer";
import PaymentTransferSetting from "./pages/PaymentTransferSetting";
import PaymentTransferLog from "./pages/PaymentTransferLog";
import ShowBetfair from "./pages/RiskManagement/ShowBetfair";




// http://localhost:5001/v1/admin/profile-list?page=1&page_size=2&keyword=&status=&created_by=637db6d823122645cf571d01
// http://localhost:5001/v1/admin/transaction/logs?page=1&limit=10&user_id=637f5c1980bda05ee7797f84
// http://localhost:5001/v1/admin/search-user?keyword=loku
export const routes = [
  {
    path: "/",
    Component: Home,
    permission: [],
  },
  {
    path: "/:id/:user_type",
    Component: Home,
    permission: [],
  },
  {
    path: "/account-summary",
    Component: AccountSummary,
    permission: [],
  },
  {
    path:"/match-details/:eventId/:marketId",
    Component: SportAnalytics,
    permission: [],
  },
  {
    path: "/betting-history",
    Component: BettingHistory,
    permission: [],
  },
  {
    path: "/TransactionHistory",
    Component: TransactionHistory,
    permission: [],
  },

  {
    path: "/events",
    Component: Events,
    permission: [],
  },
  {
    path: "/activitylog",
    Component: ActivityLog,
    permission: [],
  },
  {
    path: "/betting-profit-loss",
    Component: BettingProfitLoss,
    permission: [],
  },
  {
    path: "/account-summary/:id/:type",
    Component: AccountSummary,
    permission: [],
  },

  {
    path: "/casino/:type",
    Component: CasinoListing,
    permission: [],
  },
  {
    path: "/betting-history/:id/:type",
    Component: BettingHistory,
    permission: [],
  },
  {
    path: "/current-bets",
    Component: UserBetListLive,
    permission: [],
  },

  {
    path: "/report/:type",
    Component: ReportType,
    permission: [],
  },

  {
    path: "/recentlyCreatedAccount",
    Component: RecentCreatedAccount,
    permission: [],
  },
  
  {
    path: "/current-bets/:id/:type",
    Component: UserBetListLive,
    permission: [],
  },

  {
    path: "/transaction-history/:id",
    Component: TransactionHistory,
    permission: [],
  },
  {
    path: "/transaction-history/:id/:type",
    Component: TransactionHistory,
    permission: [],
  },
  {
    path: "/currentBets/:id/:type",
    Component: CurrentBets,
    permission: [],
  },
  {
    path: "/transaction-history/:id/:type",
    Component: TransactionHistory,
    permission: [],
  },
  {
    path: "/transaction-history-2/:id/:type",
    Component: TransactionHistory2,
    permission: [],
  },
  {
    path: "/transaction-history-2/:id",
    Component: TransactionHistory2,
    permission: [],
  },
  {
    path: "/AECasionBets/:id/:type",
    Component: ViewAECasionBets,
    permission: [],
  },
  {
    path: "/InternationalCasionBets/:id/:type",
    Component: ViewCasinoBetDialog,
    permission: [],
  },
  {
    path: "/activity-log/:id",
    Component: ActivityLog,
    permission: [],
  },
  {
    path: "/activity-log/:id/:type",
    Component: ActivityLog,
    permission: [],
  },
  {
    path: "/betting-profit-loss/:id/:type",
    Component: BettingProfitLoss,
    permission: [],
  },

  {
    path: "/my-account-summary",
    Component: MyAccountSummary,
    permission: [],
  },
  {
    path: "/my-account-statement",
    Component: MyAccountStatement,
    permission: [],
  },
  {
    path: "/my-profile",
    Component: MyProfile,
    permission: [],
  },
  {
    path: "/my-activity-log",
    Component: MyActivityLog,
    permission: [],
  },
  {
    path: "/transaction-logs",
    Component: TransactionLogs,
    permission: [],
  },
  {
    path: "/transaction-logs/:id",
    Component: TransactionLogs,
    permission: [],
  },

  {
    path: "/statements/:id",
    Component: Statements,
    permission: [],
  },
  {
    path: "/Betlist",
    Component: BetList,
    permission: [],
  },
  {
    path: "/BetListLive",
    Component: BetListLive,
    permission: [],
  },
  {
    path: "/AprofitDownline",
    Component: AprofitDownline,
    permission: [],
  },

  {
    path: "/AprofitByDownline",
    Component: AprofitByDownline,
    permission: [],
  },

  {
    path: "/AprofitDownline/:id/:user_type",
    Component: AprofitDownline,
    permission: [],
  },
  {
    path: "/AprofitByDownline/:id/:user_type",
    Component: AprofitByDownline,
    permission: [],
  },
  {
    path: "/AprofitMarket",
    Component: AprofitMarket,
    permission: [],
  },
  {
    path: "/AprofitPlayer",
    Component: AprofitPlayer,
    permission: [],
  },
  {
    path: "/AprofitCasino",
    Component: AprofitCasino,
    permission: [],
  },
  {
    path: "/Adownlinesportspl",
    Component: Adownlinesportspl,
    permission: [],
  },
  {
    path: "/ACdownlinesportspl",
    Component: ACdownlinesportspl,
    permission: [],
  },
  {
    path: "/ACdownlinesportspl/:id/:user_type",
    Component: ACdownlinesportspl,
    permission: [],
  },
  {
    path: "/ACasinoprofitAndLossDownlineNew",
    Component: ACasinoprofitAndLossDownlineNew,
    permission: [],
  },
  {
    path: "/ACasinoprofitAndLossDownlineNew/:id/:user_type",
    Component: ACasinoprofitAndLossDownlineNew,
    permission: [],
  },
  {
    path: "/ICasinoprofitAndLossDownlineNew",
    Component: ICasinoprofitAndLossDownlineNew,
    permission: [],
  },
  {
    path: "/ICasinoprofitAndLossDownlineNew/:id/:user_type",
    Component: ICasinoprofitAndLossDownlineNew,
    permission: [],
  },

  {
    path: "/StatementByUser",
    Component: StatementByUser,
    permission: [],
  },
  {
    path: "/RiskManagement",
    Component: RiskManagement,
    permission: [],
  },
  {
    path: "/matchedAll",
    Component: MatchedAll,
    permission: [],
  },
  {
    path: "/banking",
    Component: Banking,
    permission: [],
  },
  {
    path: "/SetBookMarker",
    Component: SetBookMarker,
    permission: ["owner", "super_admin"],
  },

  {
    path: "/block-market",
    Component: BlockMarket,
    permission: ["owner","sub_owner" ,"super_admin"], //"owner", "super_admin"
  },
  // {
  //   path: "/sport-setting",
  //   Component: SportSetting,
  //   permission: ["owner"],
  // },
  {
    path: "/SetLimit/:id/:type",
    Component: SetLimit,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/Manupulation",
    Component: Manupulation,
    permission: ["owner", "super_admin"],
  },
  // {
  //   path: "/add-match",
  //   Component: AddMatch,
  //   permission: ["owner", "super_admin"],
  // },
  {
    path: "/general-setting",
    Component: GeneralSetting,
    permission: ["owner","sub_owner"], //"owner", "super_admin"
  },
  {
    path: "/setting",
    Component: Setting,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/manage-links",
    Component: ManageLinks,
    permission: ["owner","sub_owner" ],
  },
  {
    path: "/add-website",
    Component: AddWebsite,
    permission: ["owner","sub_owner" ],
  },
  {
    path: "/FancyResult",
    Component: FancyResult,
    permission: ["owner"],
  },
  {
    path: "/MarketResult",
    Component: MarketResult,
    permission: ["owner"],
  },
  {
    path: "/TossResult",
    Component: TossResult,
    permission: ["owner"],
  },
  {
    path: "/LiveMatchBet",
    Component: LiveMatchBet,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/BetCount",
    Component: BetCount,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/PreMatch",
    Component: PreMatch,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/MatchProfitLoss/:id",
    Component: MatchProfitLoss,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/ShowBetsCR/:eventId/:userId",
    Component: ShowBetsCR,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/DisplayMatchBet/:eventId",
    Component: DisplayMatchBet,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/DisplaySessionBet/:eventId",
    Component: DisplaySessionBet,
    permission: ["owner", "super_admin"],
  },

  {
    path: "/SearchMatch",
    Component: SearchMatch,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/PlayerBalance",
    Component: PlayerBalance,
    permission: [], //"owner", "super_admin"
  },

  {
    path: "/UserProfitLoss",
    Component: UserProfitLoss,
    permission: ["owner", "super_admin"],
  },

  {
    path: "/result",
    Component: Result,
    permission: ["owner"],
  },
  {
    path: "/WebsiteSetting",
    Component: WebsiteSetting,
    permission: ["owner","sub_owner"],
  },
  {
    path: "/rejected-bets",
    Component: RejectedBets,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/fancy-details/:id",
    Component: ViewFancyDetails,
    permission: ["owner", "super_admin"],
  },
  {
    path: "/searchuser",
    Component: SearchUser,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/defaultsetting",
    Component: DefaultSetting,
    permission: ["owner","sub_owner" ,"super_admin"],
  },
  {
    path: "/SurveillanceSetting",
    Component: SurveillanceSetting,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/active-match",
    Component: ActiveMatch,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/in-active-match",
    Component: InActiveMatch,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/SuspendedResult",
    Component: SuspendedResult,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/user-message",
    Component: UserMessage,
    permission: ["owner","sub_owner" ,"super_admin", "agent"],
  },
  {
    path: "/hyper-message",
    Component: HyperMessage,
    permission: ["owner"],
  },
  {
    path: "/importantmessage",
    Component: ImportantMessage,
    permission: ["owner"],
  },
  {
    path: "/inactive-users",
    Component: InactiveUsers,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/BetLockUser",
    Component: BetLockUser,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/SuspendedMarketResult",
    Component: SuspendedMarketResult,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/SuspendedFancyResult",
    Component: SuspendedFancyResult,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/viewBets/:eventId",
    Component: viewBets,
    permission: [], //"owner", "super_admin"
  },
  {
    path: "/updateFancyStatus",
    Component: updateFancyStatus,
    permission: ["owner", "super_admin"], //"owner", "super_admin"
  },
  {
    path: "/banner-list",
    Component: BannerList,
    permission: ["owner", "sub_owner","super_admin"],
  },
  {
    path: "/Other",
    Component: Other,
    permission: ["owner", "super_admin"],
  },

  {
    path: "/p2p-transfer",
    Component: P2pTransfer,
    permission: [], //"owner", "super_admin","agent"
  },
  {
    path: "/payment-transfer-setting",
    Component: PaymentTransferSetting,
    permission: [], //"owner", "super_admin","agent"
  },
  {
    path: "/payment-transfer-log",
    Component: PaymentTransferLog,
    permission: [], //"owner", "super_admin","agent"
  }
 
];
