const pathObj = {
  loginUser: "/v1/admin/login",
  refreshToken: "/v1/admin/refresh-token",
  profileList: "/v1/admin/profile-list",
  downLineList: "/v1/admin/downline-list",
  addProfile: "/v1/admin/add-profile",
  checkProfile: "/v1/admin/check-profile",
  metaData: "/v1/admin/meta-data",
  refreshAmount: "/v1/admin/refresh-amount",
  refreshExposureAmount: "/v1/admin/refresh-exposure-amount",
  userProfile: "/v1/admin/profile",
  activityLog: "/v1/admin/activity-logs",
  transactionLogs: "/v1/admin/transaction/logs",
  checkLimit: "/v1/admin/transaction/check-limit",
  transactionBetLogs: "/v1/admin/transaction/bet-logs",
  getInternationalCasinoBets: "/v1/admin/bets/casono-bet-history",
  matchList: "/v1/admin/match/add-match-list",
  updateAllAddMatchList: "/v1/admin/match/update-multiple",
  changePassword: "/v1/admin/change-password",
  updateMatchStatus: "/v1/admin/match/update-status",
  depositChips: "/v1/admin/deposit-chips",
  searchUser: "/v1/admin/search-user",
  profileChangeStatus: "/v1/admin/profile-change-status",
  sportList: "/v1/admin/sport/list",
  updateSportStatus: "/v1/admin/sport/update-status",
  updateMatch: "/v1/admin/match/update-match",
  bankingCreate: "/v1/admin/transaction/create",
  createWebsite: "/v1/admin/create-website",
  listWebsite: "/v1/admin/list-website",
  userMessageCreate: "/v1/admin/message",
  userMessageList: "/v1/admin/message/list",
  userMessageDetail: "/v1/admin/message/detail",
  userMessageUpdate: "/v1/admin/message/update",
  importantMessage: "/v1/admin/message/important",
  messageStatusUpdate: "/v1/admin/message/status-update",
  updateUserReferenceAmount: "/v1/admin/update-reference-amount",
  updateAwcLimit: "/v1/admin/update-awc-limit",
  getBetslive: "/v1/admin/bets/live-bet-list",
  getBets: "/v1/admin/bets/bet-list",
  updateBetsStatus: "/v1/admin/bets/update-bet-status",
  rejectedBets: "/v1/admin/bets/rejected-bet-list",
  updateStatusRequestBets: "/v1/admin/match/update-status/",
  getFancyBetsDetails: "/v1/admin/match/detail",
  updateSettingLimit: "/v1/admin/match/update-match-limit-by-eventid",
  updateDefaultSetting: "/v1/admin/settings/global-limit-setting-update",
  getDefaultSetting: "/v1/admin/settings/global-limit-setting-list",
  beforeInplayLimit: "/v1/admin/settings/before-inplay-limit",
  siteSettingUpdate: "/v1/admin/site-setting/site-setting-update",
  matchFilterList: "/v1/admin/match/filter-list",
  fancyList: "/v1/admin/match/fancy-list",
  // resultFancyPremiumList: "/v1/admin/match/result-fancy-premium-list",
  resultFancyPremiumList: "/v1/admin/match/result-fancy-premium-list-new",
  eventFancyBets: "/v1/admin/bets/event-fancy-bets",
  fancyBetResult: "/v1/admin/bet-result/fancy",
  premiumFancyBetResult: "/v1/admin/bet-result/sportBook",
  tossBetResult: "/v1/admin/bet-result/toss",
  deleteFancy: "/v1/admin/match/fancy-delete",
  getExpouserData: "/v1/admin/exposure-list",
  updateExposure: "/v1/admin/update-exposure",
  updateCommission: "/v1/admin/update-commission",
  resultsSetMarker: "/v1/admin/match/result-match-list",
  addMatchAds: "/v1/admin/match/add-match-ad",
  betHistory: "/v1/admin/bets/bet-history",
  eventsBets: "/v1/admin/bets/events-bets",
  betProfitLoss: "/v1/admin/bets/profit-loss-bet",
  messageList: "/v1/admin/message/downline?type=downline",
  betfairResult: "/v1/admin/bet-result/betfair",
  tieResult: "/v1/admin/bet-result/tie",
  bookmakerResult: "/v1/admin/bet-result/bookmaker",
  resultTieandAbonded: "/v1/admin/match/update-status-by-eventid",
  reportDownline: "/v1/admin/report/downline",
  reportDownlineDetail: "/v1/admin/report/downline-list",
  reportMarketDetail: "/v1/admin/report/match-market",
  reportMatchMarketBets: "/v1/admin/report/match-market-bets",
  ACasinoprofitAndLossDownlineNew: "/v1/admin/report/casino-profit-loss",
  reportMarket: "/v1/admin/report/market",
  reportPlayer: "/v1/admin/report/player",
  casinoMarket: "/v1/admin/report/casino",
  aprofitCasino: "/v1/admin/report/casino-profit-loss-by-date",
  setBookmaker: "/v1/cron/match-bookmaker-list-save",
  setFancy: "/v1/cron/match-fancy-list-save",
  setFancyupdate: "/v1/cron/fancy-list-save",
  setBookmakerUpdate: "/v1/cron/bookmaker-list-save",
  blockmarketList: "/v1/admin/tournament/series",
  updateBlockListinStatus: "/v1/admin/tournament/block-market",
  updateSportsListingStatus: "/v1/admin/sport/update-status",
  updateMatchOddsListingStatus: "/v1/admin/sport/match-on-off",
  riskProfile: "/v1/admin/risk-profile-list",
  riskManagementDetails: "/v1/admin/match/detail",
  riskManagementSPortPremiumDetails: "/v1/admin/match/risk-premium-list-book",
  riskManagementFancy: "/v1/admin/match/risk-fancy-list",
  riskManagementPremium: "/v1/admin/match/risk-premium-list",
  updateFancyStatus: "/v1/admin/match/update-fancy-status",
  updatePremiumFancyStatus: "/v1/admin/match/update-premiumfancy-status",
  downlineDataRisk: "/v1/admin/match-profile-list",
  fancyPosition: "/v1/admin/match/fancy-bet-position",
  depositAmount: "/v1/admin/deposit-amount",
  lockedUserList: "/v1/admin/locked-user-list",
  userList: "/v1/admin/user-list",
  userProfitLoss: "/v1/admin/profit-loss",
  riskMatchListBookmaker: "/v1/admin/match/risk-bookmaker-match-list",
  submitSetting: "/v1/admin/setting",
  settingList: "/v1/admin/setting-list",
  riskMatchList: "/v1/admin/match/risk-match-list",
  cheatMatchList: "/v1/admin/match/cheat-match-list",
  getCheatBet: "/v1/admin/match/cheater-bet-list",
  getCheaterBetList: "/v1/admin/match/cheater-list",
  matchProfitLoss: "/v1/admin/match-profit-loss",
  userEventsBets: "/v1/admin/bets/user-events-bets",
  preMatchEventsBets: "/v1/admin/bets/prematch-events-bets",
  eventSessionBets: "/v1/admin/bets/event-session-bets",
  withdrawCasinoAmount: "/v1/wallet/agent-withdraw",
  tournamentList: "/v1/cron/tournament-list",
  cricketTournamentMatch: "/v1/cron/cricket-tournament-match",
  tennisTournamentMatch: "/v1/cron/tennis-tournament-match",
  soccerTournamentMatch: "/v1/cron/soccer-tournament-match",
  casinoHistory: "/v1/wallet/transaction-list",
  casinoWalletHistory: "/v1/wallet/transaction-history",
  updateWebsiteSetting: "/v1/admin/update-website-setting",
  getWebsiteLinkData: "/v1/admin/website-setting",
  getAECasinoBets: "/v1/admin/transaction/ae-casino-transaction",
  UserLiveBet: "/v1/admin/bets/bet-list-live",
  riskListAll: "/v1/admin/match/risk-profile-list-all",
  //new routes
  reportByDownline: "/v1/admin/report/bydownline",
  casinoAllMarket: "/v1/admin/report/all-casino",
  ICasinoprofitAndLossDownlineNew:
    "/v1/admin/report/international-casino-profit-loss",

  blockCasinoList: "/v1/admin/sport/get-casino-market",
  updateBlockCasinoStatus: "/v1/admin/sport/casino-block-market",
  bannerList: "/v1/admin/banner-list",
  deleteBanner: "/v1/admin/delete-banner",
  uploadBanner: "/v1/admin/banner-upload",
  addBanner: "/v1/admin/add-banner",

  reportAwc: "/v1/admin/report/awccasino",
  reportAwcPlayerPl: "/v1/admin/report/awccasino-player-pl",
  reportAwcGamePl: "/v1/admin/report/awccasino-game-pl",

  reportAwcGamePlList: "/v1/admin/report/awccasino-game-pl-list",


  reportAura: "/v1/admin/report/auracasino",
  reportAuraPlayerPl: "/v1/admin/report/auracasino-player-pl",
  reportAuraGamePl: "/v1/admin/report/auracasino-game-pl",

  reportEgt: "/v1/admin/report/egtcasino",
  reportEgtPlayerPl: "/v1/admin/report/egtcasino-player-pl",
  reportEgtGamePl: "/v1/admin/report/egtcasino-game-pl",


  getMatchOdds: "/v1/cron/match-odds",
  getFancyOdds: "/v1/cron/fancy-odds",
  bookmakerOdds:"/v1/cron/bookmaker-odds",

  getCasinoNew:"/v1/admin/bets/casino-bet-list",
  riskBetfair:"/v1/admin/match/risk-betfair",

};
export default pathObj;
